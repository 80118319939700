import React from "react"

export default function Home() {
  return <div>
    <h1>There is nothing to see here at this time.</h1>

    <footer>The favicon for this site was generated by <a href="https://favicon.io/">Favicon.io</a> using the <a href="https://github.com/twitter/twemoji/blob/master/assets/svg/1f4ad.svg">Thought Balloon</a> emoji by <a href="https://github.com/twitter/twemoji">Twitter, Inc and other contributors</a>. It is licensed under <a href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>.
    </footer>
  </div>
}
